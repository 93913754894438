import React from 'react';
import { Helmet } from 'react-helmet';

const GhibliPrivacyPolicy = () => {
  const handleExternalLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="privacy-policy-container">
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      <Helmet>
        <title>Privacy Policy - Ghibli Art Generator</title>
        <meta name="description" content="Privacy policy for Ghibli Art Generator application" />
      </Helmet>

      <div className="privacy-policy-content">
        <h1 className="policy-title">Privacy Policy for Ghibli Art Generator</h1>
        <p className="last-updated">Last Updated: {new Date().toLocaleDateString()}</p>
        
        <section className="policy-section">
          <h2 className="section-title">1. Information We Collect</h2>
          <h3 className="section-subtitle">a) User-Provided Information:</h3>
          <ul className="policy-list">
            <li><strong>Text Prompts:</strong> The descriptions you input to generate artwork</li>
            <li><strong>Generated Images:</strong> The AI-created images you choose to save</li>
            <li><strong>Optional Permissions:</strong> Photo Library access if you save images</li>
          </ul>
          
          <h3 className="section-subtitle">b) Automatically Collected Information:</h3>
          <ul className="policy-list">
            <li><strong>Device Information:</strong> Device type, OS version, unique identifiers</li>
            <li><strong>Usage Data:</strong> Feature usage frequency, session duration</li>
            <li><strong>Advertising Data:</strong> Interactions with ads (via Google AdMob)</li>
          </ul>
        </section>

        <section className="policy-section">
          <h2 className="section-title">2. How We Use Information</h2>
          <ul className="policy-list">
            <li>Generate and improve AI artwork based on your prompts</li>
            <li>Maintain and improve app functionality</li>
         
            <li>Analyze usage patterns to enhance user experience</li>
            <li>Troubleshoot technical issues</li>
          </ul>
        </section>

        <section className="policy-section">
          <h2 className="section-title">3. Third-Party Services</h2>
          <p>The app uses these third-party services that may collect information:</p>
          <ul className="policy-list">
            <li>
              <button 
                className="link-button"
                onClick={() => handleExternalLink('https://policies.google.com/privacy')}
              >
                <strong>Google AdMob</strong>
              </button> - Shows personalized ads based on your interests
            </li>
            <li><strong>AI Service Providers</strong> - Processes your prompts to generate images</li>
            <li><strong>Analytics Tools</strong> - For usage statistics and error reporting</li>
          </ul>
        </section>

        <section className="policy-section">
          <h2 className="section-title">4. Data Storage & Security</h2>
          <ul className="policy-list">
            <li><strong>Generated Images:</strong> Stored locally on your device unless explicitly saved</li>
            <li><strong>Prompts:</strong> Not permanently stored after image generation</li>
            <li><strong>Security Measures:</strong> Industry-standard encryption for data transmission</li>
          </ul>
        </section>

        

        <section className="policy-section">
          <h2 className="section-title">6. Your Choices</h2>
          <p>You can:</p>
          <ul className="policy-list">
            <li>Revoke photo library permissions via device settings</li>
            <li>Reset your advertising ID</li>
        
          </ul>
        </section>

        <section className="policy-section">
          <h2 className="section-title">7. Changes to This Policy</h2>
          <p>We may update this policy. Continued use after changes constitutes acceptance.</p>
        </section>

        <section className="policy-section contact-section">
          <h2 className="section-title">8. Contact Us</h2>
          <p>For questions about this policy:</p>
          <p>Email: <a href="mailto:contact@cloudsoftware.tn" className="contact-link">contact@cloudsoftware.tn</a></p>
        </section>
      </div>
    </div>
  );
};

export default GhibliPrivacyPolicy;