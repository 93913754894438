import React from 'react';
import './PhotoForgePrivacy.css'; 
const PhotoForgePrivacy = () => {
  return (
    <div className="privacy-policy">
      <header className="policy-header">
        <h1>Photo Forge Privacy Policy</h1>
        <p className="last-updated">Last Updated: {new Date().toLocaleDateString()}</p>
      </header>

      <section className="policy-section">
        <h2>1. Introduction</h2>
        <p>Photo Forge ("the App") is committed to protecting your privacy. This policy explains how we handle your information when you use our mobile photo editing application.</p>
      </section>

      <section className="policy-section">
        <h2>2. Information We Collect</h2>
        
        <div className="subsection">
          <h3>2.1 Photos and Media</h3>
          <ul>
            <li>The App only accesses photos you explicitly choose to edit</li>
            <li>All image processing occurs locally on your device</li>
            <li>We never upload, store, or share your photos without your consent</li>
            <li>Processed images are saved only to your device's gallery if you choose to save them</li>
          </ul>
        </div>
        
        <div className="subsection">
          <h3>2.2 App Analytics</h3>
          <ul>
            <li>Basic usage statistics (features used, session length)</li>
            <li>Device type and OS version (for compatibility purposes)</li>
            <li>Crash reports (to improve app stability)</li>
          </ul>
        </div>
      </section>

      <section className="policy-section">
        <h2>3. Data Security</h2>
        <ul>
          <li>All photo editing happens on-device - no server processing</li>
          <li>We implement industry-standard security measures</li>
          <li>No personal identification is collected through analytics</li>
        </ul>
      </section>

      <section className="policy-section">
        <h2>4. Third-Party Services</h2>
        <p>The App don't use third-party services that may collect information:</p>
        
      </section>

      <section className="policy-section">
        <h2>5. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Request access to any data we may have</li>
 
        </ul>
      </section>

      <section className="policy-section">
        <h2>6. Changes to This Policy</h2>
        <p>We may update this policy occasionally. We'll notify users of significant changes through the app.</p>
      </section>

      <div className="contact-section">
        <h2>Contact Us</h2>
        <p>For any privacy-related questions, please contact us at:</p>
        <a href="mailto:contact@cloudsoftware.tn" className="contact-link">
          contact@cloudsoftware.tn
        </a>
      </div>
    </div>
  );
};

export default PhotoForgePrivacy;