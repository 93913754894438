import React from "react";

const PeriodPrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <h1 style={styles.heading}>Privacy Policy for ZahraFlow</h1>
      <p style={styles.lastUpdated}>Last Updated: March 20, 2025</p>

      <p>
        Welcome to <strong>ZahraFlow</strong>, a period tracking app developed by{" "}
        Cloud Software ("we," "us," or "our"). We are committed to
        protecting your privacy and ensuring the security of your personal
        information. This Privacy Policy explains how we collect, use, disclose, and
        safeguard your data when you use <strong>ZahraFlow</strong> (the "App"). By
        using the App, you agree to the terms outlined in this Privacy Policy.
      </p>

      <h2 style={styles.subheading}>1. Information We Collect</h2>
      <p>We may collect the following types of information when you use ZahraFlow:</p>

      <h3>1.1 Personal Information</h3>
      <ul>
        <li>
          <strong>Health Data</strong>: Menstrual cycle details like start dates, cycle
          length, symptoms, and flow intensity.
        </li>
        <li>
          <strong>Account Information</strong>: If applicable, your name, email, or other
          identifiers.
        </li>
        <li>
          <strong>Contact Information</strong>: If you contact support, we may collect your
          email.
        </li>
      </ul>

      <h3>1.2 Non-Personal Information</h3>
      <ul>
        <li>
          <strong>Usage Data</strong>: App interaction, feature usage, and time spent.
        </li>
        <li>
          <strong>Device Information</strong>: Device type, OS, unique identifiers, and crash
          reports.
        </li>
      </ul>

      <h2 style={styles.subheading}>2. How We Use Your Information</h2>
      <ul>
        <li>Track menstrual cycles and generate predictions.</li>
        <li>Personalize user experience.</li>
        <li>Improve App performance and provide customer support.</li>
      </ul>

      <h2 style={styles.subheading}>3. How We Share Your Information</h2>
      <p>We do not sell or share your personal data.</p>
    

      <h2 style={styles.subheading}>4. Data Storage and Security</h2>
      <ul>
        <li>Your data is stored securely on your device.</li>
        <li>We use encryption and access controls for security.</li>
      </ul>

      <h2 style={styles.subheading}>5. Your Choices and Rights</h2>
      <ul>
        <li>Edit or delete personal data within the App.</li>
        <li>Request data deletion by contacting us.</li>
        <li>Rights under Tunisian Law No. 2004-63 and GDPR (if applicable).</li>
      </ul>

      <h2 style={styles.subheading}>6. Contact Us</h2>
      <p>
        Email: <strong>contact@cloudsoftware.tn</strong>
      
      </p>

      <h2 style={styles.subheading}>7. Changes to This Privacy Policy</h2>
      <p>
        We may update this policy. The latest version will always be available in the App.
      </p>
    </div>
  );
};

// Inline styles for the component
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
    color: "#333",
  },
  heading: {
    fontSize: "24px",
  },
  subheading: {
    fontSize: "20px",
    marginTop: "20px",
  },
  lastUpdated: {
    fontStyle: "italic",
    color: "#888",
  },
};

export default PeriodPrivacyPolicy;
